import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";

const Hero = dynamic(() => import("../Home/Hero"));
const Services = dynamic(() => import("../Home/Services"));
const WorkProcess = dynamic(() => import("../Home/WorkProcess"));
const WhyHire = dynamic(() => import("../Home/WhyHire"));
const CaseStudy = dynamic(() => import("../Home/CaseStudy"));
const Faq = dynamic(() => import("../Home/Faq"));
const ClientTestimonials = dynamic(() => import("../Home/ClientTestimonials"));
const BehindService = dynamic(() => import("../Home/BehindService"));
const Loader = dynamic(() => import("../common/Loader/Loader"));
const CommonCtaSection = dynamic(
  () => import("../common/CTASection/CommonCtaSection"));

const Home = ({ homeData }) => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 100);

    console.clear();
  }, []);
  return (
    <>
      <main className="main-wrapper  wrapper-top-bg">
        <article className="wrapper-inner">
          {loader && <Loader />}

          {homeData?.map((val, index) => {
            return (
              <React.Fragment key={index}>
                {val?.type === "banner" && (
                  <Hero data={homeData} bannerData={val} />
                )}
                {val?.type === "exquisite_services" && (
                  <Services data={homeData} exquisiteData={val} />
                )}
                {val?.type === "behind_services" && (
                  <BehindService data={homeData} behindServiceData={val} />
                )}
                {val?.type === "work_process" && (
                  <WorkProcess data={homeData} WorkProcessData={val} />
                )}
                {val?.type === "why_hire" && (
                  <WhyHire data={homeData} whyHireData={val} />
                )}
                {val?.type === "case_studies_block" && (
                  <CaseStudy data={homeData} caseStudyData={val} />
                )}
                {val?.type === "client_testimonial" && (
                  <ClientTestimonials
                    data={homeData}
                    clientTestimonialsData={val}
                  />
                )}
                {val?.type === "faq" && <Faq data={homeData} faqData={val} />}
                {val?.type === "cta" && <CommonCtaSection data={val} />}
              </React.Fragment>
            );
          })}
        </article>
      </main>
    </>
  );
};

export default Home;
